<template>
    <div>
        <div class="pageheader pd-t-25 pd-b-15">
            <div class="row">
                <div class="col-7">
                    <div class="pd-t-5 pd-b-5">
                        <h1 class="pd-0 mg-0 tx-20 text-overflow">Laporan Perubahan Fisik</h1>
                    </div>
                    <div class="breadcrumb pd-0 mg-0">
                        <a class="breadcrumb-item" href="#"><i class="fa fa-check-square-o mg-r-5"></i>Laporan</a>
                        <span class="breadcrumb-item active"> Perubahan Fisik</span>
                    </div>
                </div>
                <div v-if="userdata.role == 'auditor'  || userdata.role == 'administrator'" class="col-5">
                    Filter SKPD:
                    <v-select 
                            :options="listPengguna"
                            :reduce="(label) => label.code"
                            label="label"
                            v-model="filter"
                            @input="handleFilter"
                            ></v-select>
                    <hr>
                    <div class="webnots-success webnots-notification-box"><b>Total Penyaluran</b> : Rp. {{ calculation | VMask(currencyMask) }}</div>
                </div>
                <div v-if="userdata.role != 'auditor' && userdata.role != 'administrator'"  class="col-5">
                    <div class="webnots-success webnots-notification-box"><b>Total Nilai</b> : Rp. {{ calculation | VMask(currencyMask) }}</div>
                </div>
            </div>
        </div> 

        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                
                <!-- CONTENT -->
                <div class="tab-content mb-3">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="card-header">
                                <div class="input-group input-group-flush input-group-merge">
                                    <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari Spesifikasi Barang...">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-search"></span>
                                        </div>
                                    </div>
                                    <select v-model="filterMode" v-on:change="filterTipe()" class="ml-3" :style="'width:100px'">
                                        <option value="custom">Custom</option>
                                        <option value="semester1">Semester 1</option>
                                        <option value="semester2">Semester 2</option>
                                    </select>
                                    <datepicker v-if="filterMode != 'custom'" disabled :input-class="'form-control border-danger'" :style="'width:130px'" required=""
                                                v-model="form.start_date" placeholder="Tanggal Awal"></datepicker>
                                    <datepicker v-if="filterMode != 'custom'" disabled :input-class="'form-control border-danger'" :style="'width:130px'" required="" :disabledDates="disabledDates"
                                                v-model="form.end_date" placeholder="Tanggal Akhir"></datepicker>

                                    <datepicker v-if="filterMode == 'custom'" :input-class="'form-control border-dark'" :style="'width:130px'" required=""
                                                v-model="form.start_date" placeholder="Tanggal Awal"></datepicker>
                                    <datepicker v-if="filterMode == 'custom'" :input-class="'form-control border-dark'" :style="'width:130px'" required="" :disabledDates="disabledDates"
                                                v-model="form.end_date" placeholder="Tanggal Akhir"></datepicker>

                                    <button type="button" v-on:click="getData()" class="ml-2 btn btn-secondary"><i class="fa fa-filter"></i></button>
                                    <!-- <a target="_blank" :href="'https://persediaanbmd.bandungkab.go.id/print/Home/perubahan_fisik/s6TK25w6tjXgTSx6j9seyBxVrse1HnF621tFGYU0Lo6on6PsyZ7jnkhbjf123kmasdhb12934masdujkasmgas/' + userdata.id + '/' + '/' + form.start_date + '/' + form.end_date " class="ml-2 btn btn-danger text-white"><i class="fa fa-file mg-r-5"></i> Terbitkan BA</a> -->
                                    <!-- <a target="_blank" :href="'http://localhost/persediaan-print/public/Home/perubahan_fisik/s6TK25w6tjXgTSx6j9seyBxVrse1HnF621tFGYU0Lo6on6PsyZ7jnkhbjf123kmasdhb12934masdujkasmgas/' + userdata.id + '/' + '/' + form.start_date + '/' + form.end_date " class="ml-2 btn btn-danger text-white"><i class="fa fa-file mg-r-5"></i> Terbitkan BA</a> -->
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="m-2 table table-sm table-striped  table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th style="width:4%">No</th>
                                            <th>Kode Barang</th>
                                            <th>Spesifikasi Barang</th>
                                            <th>Jumlah</th>
                                            <th>Harga Satuan</th>
                                            <th>Nilai</th>
                                            <th style="width:10%">Ket.</th>
                                         </tr>
                                    </thead>
                                    <tbody v-if="!fetching">
                                        <tr v-for="(item, index) in listData" :key="index">
                                            <td>{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                            <td>{{ item.code }}</td>
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.jumlah }}</td>
                                            <td >Rp. {{ item.harga | VMask(currencyMask) }}</td>
                                            <td >Rp. {{ item.total | VMask(currencyMask) }}</td>
                                            <td>{{ item.keterangan }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="fetching">
                                        <tr>
                                            <td colspan="7" class="text-center">
                                                <div class="sk-wave">
                                                    <div class="sk-rect sk-rect1 mr-1"></div>
                                                    <div class="sk-rect sk-rect2 mr-1"></div>
                                                    <div class="sk-rect sk-rect3 mr-1"></div>
                                                    <div class="sk-rect sk-rect4 mr-1"></div>
                                                    <div class="sk-rect sk-rect5"></div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" style="background-color:#f2f2f2" class="text-center">
                                                ⚠️ Semakin Banyak Transaksi, maka semakil lama proses pengambilan data. <br> Karena terdapat algoritma Kalkulasi hitung transaksi <br> Sebaiknya gunakan fitur pencarian 
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="!fetching && listData.length == 0">
                                        <tr>
                                            <td colspan="7" class="text-center">
                                                - Data Tidak Tersedia -
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer border-top-0">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage"
                                                :page-count="pagination.totalPages"
                                                :classes="pagination.bootstrapPaginationClasses"
                                                :labels="pagination.paginationAnchorTexts"
                                                v-on:change="getData()"></v-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ADD SPACE -->
                <br><br><br>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";
    import vPagination from 'vue-plain-pagination'
    import Datepicker from 'vuejs-datepicker';
    import moment from 'moment';

    import Vue from 'vue'
    import VueMask from 'v-mask'
    Vue.use(VueMask);


    import createNumberMask from 'text-mask-addons/dist/createNumberMask';
    const currencyMask = createNumberMask({
        prefix: '',
        allowDecimal: true,
        decimalLimit: 7,
        includeThousandsSeparator: true,
        allowNegative: false,
    });
    export default {
        metaInfo: {
            title: 'SI',
            titleTemplate: '%s - Data Persediaan'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                tokenFix: localStorage.auth_token,
                currencyMask,
                listData: [],
                listPengguna: [],
                fetching: true,
                calculation: 0,
                filterMode: 'custom',
                filter: '',
                form: {
                    start_date: new Date('2024-01-01'),
                    end_date: new Date('2024-12-31'),
                },
                pagination: {         
                    currentPage: 1,
                    totalPages: 0,
                    limit: 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'  
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
                showSelectYear: true
                
            }
        },
        created() {
            this.$store.dispatch('loadUserData');
            this.getData();
            this.getUser();
        },
        computed: {
            ...mapState(['userdata']),
            disabledDates() {
                return {
                    to: new Date(this.form.start_date),
                };
            },
        },
        components: {
            vPagination,
            Datepicker,
            'v-select' : vSelect,
        },
        methods: {
            convertDate : function (date) {
                return moment(date, 'YYYY-MM-DD').format('yyyy-MM-DD');
            },
            filterTipe(){
                if(this.filterMode == 'semester1'){
                    this.form.start_date = new Date('2024-01-01')
                    this.form.end_date = new Date('2024-06-30')
                }else if(this.filterMode == 'semester2'){
                    this.form.start_date = new Date('2024-07-01')
                    this.form.end_date = new Date('2024-12-31')
                }else{
                    this.form.start_date = new Date('2024-01-01')
                    this.form.end_date = new Date('2024-12-31')
                }
            },
            getData() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'laporan/perubahan_fisik', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                        start: new Date(this.form.start_date),
                        end: new Date(this.form.end_date),
                        filter: this.filter
                    }
                })
                    .then((response) => {
                        this.listData = response.data.data;
                        this.calculation = response.data.calculation;
                        this.pagination.totalPages = response.data.total_page;
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            getUser() {
                this.fetching = true;
                if(this.userdata.role == 'auditor' || this.userdata.role == 'administrator'){
                    this.$http.get(this.baseUrl + 'admin/master/pengguna/list', {
                    params: {
                            keywords: this.keywords,
                        }
                    })
                        .then((response) => {
                            let data = response.data.data;
                            data.forEach((item) => {
                                this.listPengguna.push({
                                label: item.name,
                                code: item.id,
                                });
                            });
                        })
                        .catch((error) => {
                            console.log(error.response.data);
                        }); 
                }
            },
            handleFilter: function() {
                this.getData();
            },
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>